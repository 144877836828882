<template>
  <div class="panel">
    <div class="panel_header tw-flex tw-justify-between tw-items-center">
      <page-header title="服务管理" />
    </div>
    <el-tabs v-if="isServiceAggregator" v-model="activeTab">
      <el-tab-pane label="单个服务" name="1"></el-tab-pane>
      <el-tab-pane label="组合服务" name="2"></el-tab-pane>
    </el-tabs>
    <single-service v-if="activeTab === '1'" />
    <multiple-service v-if="activeTab === '2'" />
  </div>
</template>

<script>
import singleService from './components/single-service'
import multipleService from './components/multiple-service'

export default {
  components: {
    singleService,
    multipleService
  },
  data () {
    return {
      activeTab: '1',
      select: ''
    }
  }
}
</script>

<style lang="scss" scoped>
  .panel {
    width: 100%;
    min-width: 1030px;
    min-height: 100%;
    padding: 0 30px;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    overflow: auto;
    &_header {
      padding: 20px 0;
    }
  }
</style>
