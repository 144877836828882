<!--
  服务资源
    + 单个服务管理
    + 后台下架服务如果这个服务在机构服务链中，则后台提示“无法下架”（王童）
-->
<template>
  <div class="tab">
    <div class="tab_op">
      <el-button
        type="primary"
        size="small"
        @click="createService">
        发布服务
      </el-button>
      <el-select
        size="small"
        v-model="select"
        style="margin-left:10px;"
        clearable
        placeholder="请选择服务状态"
        @change="getTableData(1)">
        <el-option
          v-for="item in statusOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
    </div>
    <div class="tab_table">
      <el-table
        :data="tableData"
        :header-cell-style="headerCellStyle"
        @row-click="onDetail"
        v-loading="tableLoading">
        <el-table-column
          label="服务名称"
          prop="title">
        </el-table-column>
        <el-table-column
          label="服务类型"
          prop="serviceTypeName">
        </el-table-column>
        <el-table-column
          label="价格"
          prop="fee">
          <template slot-scope="scope">
            <p
              class="tw-text-14px tw-font-bold"
              style="color:#3473E6;">
              {{scope.row.fee}}
            </p>
          </template>
        </el-table-column>
        <el-table-column
          label="服务状态"
          prop="logicStatus">
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.logicStatus === globalVar.SERVICE_STATUS_0"
              type="warning">
              审核中
            </el-tag>
            <el-tag
              v-if="scope.row.logicStatus === globalVar.SERVICE_STATUS_1"
              type="success">
              已通过
            </el-tag>
            <el-tag
              v-if="scope.row.logicStatus === globalVar.SERVICE_STATUS_2"
              type="danger">
              被驳回
            </el-tag>
            <el-tag
              v-if="scope.row.logicStatus === globalVar.SERVICE_STATUS_3"
              type="info">
              已下架
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          width="100px"
          label="操作">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.logicStatus === globalVar.SERVICE_STATUS_1"
              class="my-el-button-text-danger"
              type="text"
              @click.stop="onRevoke(scope.row)">
              下架
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="tab_pagination tw-flex tw-justify-center tw-items-center">
      <el-pagination
        hide-on-single-page
        background
        @current-change="pageNumChange"
        :current-page.sync="pagination.pageNum"
        :page-size="pagination.pageSize"
        layout="prev, pager, next, jumper"
        :total="pagination.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import * as api from '@/api'
import globalVar from '@/configs/globalVar'

export default {
  data () {
    return {
      tableLoading: false,
      select: '',
      tableData: [],
      // 分页对象
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      }
    }
  },
  computed: {
    headerCellStyle () {
      return {
        backgroundColor: '#F7F8F9',
        color: '#909399',
        fontWeight: 'bold'
      }
    },
    statusOptions () {
      return [
        { label: '审核中', value: this.globalVar.SERVICE_STATUS_0 },
        { label: '已通过', value: this.globalVar.SERVICE_STATUS_1 },
        { label: '被驳回', value: this.globalVar.SERVICE_STATUS_2 },
        { label: '已下架', value: this.globalVar.SERVICE_STATUS_3 }
      ]
    }
  },
  created () {
    this.getTableData(1)
  },
  methods: {
    // 发布服务
    createService () {
      const { href } = this.$router.resolve({
        path: '/service-create'
      })
      window.open(href, '_blank')
    },
    getTableData (pageNum) {
      this.tableLoading = true
      api.getMyServiceList({
        pageNum,
        pageSize: this.pagination.pageSize,
        logicStatus: this.select
      }).then(res => {
        if (res.data.code === 0) {
          this.pagination.pageNum = res.data.data.currentPage
          this.pagination.total = res.data.data.total
          this.tableData = res.data.data.list
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.tableLoading = false
      })
    },
    // 撤销
    onRevoke (row) {
      this.$msgbox({
        title: '确认',
        message: '确定要下架此服务吗？',
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            this.revokeService(row, instance, done)
          } else {
            done()
          }
        }
      })
    },
    revokeService (row, instance, done) {
      instance.confirmButtonLoading = true
      api.revokeMyService(row.id).then(res => {
        if (res.data.code === 0) {
          this.$message.success('操作成功')
          this.getTableData(this.pagination.pageNum)
        } else {
          this.$message.error(res.data.message)
        }
        done()
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        instance.confirmButtonLoading = false
      })
    },
    pageNumChange (page) {
      this.getTableData(page)
    },
    onDetail (row, column, event) {
      const logicStatus = row.logicStatus
      if (logicStatus === globalVar.SERVICE_STATUS_2) {
        window.open(`/service-detail-readonly/${row.id}`, '_blank')
      } else {
        window.open(`/service-detail/${row.id}`, '_blank')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tab {
  &_op {
    padding: 10px 0;
  }
  &_table {
    padding: 10px 0;
  }
  &_pagination {
    margin: 20px 0;
    height: 40px;
  }
}
</style>
