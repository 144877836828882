<!-- 服务链管理 -->
<template>
  <div class="tab">
    <div class="tab_op">
      <el-button
        type="primary"
        size="small"
        @click="onDetail('create')">
        服务链定制
      </el-button>
      <el-select
        size="small"
        v-model="select"
        style="margin-left:10px;"
        clearable
        placeholder="请选择服务链状态"
        @change="getTableData(1)">
        <el-option
          v-for="item in statusOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
    </div>
    <div class="tab_table">
      <el-table
        :data="tableData"
        :header-cell-style="headerCellStyle"
        @row-click="(row, column, event) => onDetail('edit', row.id)"
        v-loading="tableLoading">
        <el-table-column
          label="服务链名称"
          prop="name">
        </el-table-column>
        <el-table-column
          label="行业领域"
          prop="industryName">
        </el-table-column>
        <el-table-column
          label="服务链状态"
          prop="enabled">
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.enabled === 0"
              type="danger">
              已禁用
            </el-tag>
            <el-tag
              v-if="scope.row.enabled === 1"
              type="success">
              已启用
            </el-tag>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="tab_pagination tw-flex tw-justify-center tw-items-center">
      <el-pagination
        hide-on-single-page
        background
        @current-change="pageNumChange"
        :current-page.sync="pagination.pageNum"
        :page-size="pagination.pageSize"
        layout="prev, pager, next, jumper"
        :total="pagination.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import * as api from '@/api'

export default {
  data () {
    return {
      tableLoading: false,
      select: '',
      tableData: [],
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      } // 分页对象
    }
  },
  computed: {
    headerCellStyle () {
      return {
        backgroundColor: '#F7F8F9',
        color: '#909399',
        fontWeight: 'bold'
      }
    },
    statusOptions () {
      return [
        { label: '已禁用', value: 0 },
        { label: '已启用', value: 1 }
      ]
    }
  },
  mounted () {
    this.getTableData(1)
  },
  methods: {
    /**
     * 服务链详情
     *  + 服务链有专门的服务链详情
     *  + 服务链不需要被审核
     */
    onDetail (type, id) {
      let path = ''
      switch (type) {
        case 'create':
          path = `/service-chain/${type}`
          break
        case 'edit':
          path = `/service-chain/${type}/${id}`
          break
      }
      const { href } = this.$router.resolve({
        path
      })

      window.open(href, '_blank')
    },
    getTableData (pageNum) {
      this.tableLoading = true
      api.getMyServiceChainList({
        pageNum,
        pageSize: this.pagination.pageSize,
        enabled: this.select
      }).then(res => {
        if (res.data.code === 0) {
          this.pagination.pageNum = res.data.data.currentPage
          this.pagination.total = res.data.data.total
          this.tableData = res.data.data.list
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.tableLoading = false
      })
    },
    pageNumChange (page) {
      this.getTableData(page)
    }
  }
}
</script>

<style lang="scss" scoped>
.tab {
  &_op {
    padding: 10px 0;
  }
  &_table {
    padding: 10px 0;
  }
  &_pagination {
    margin: 20px 0;
    height: 40px;
  }
}
</style>
